import React from 'react'
import Button from '../components/button'

function Vital() {
  return (
    <div className="v-100 vh-100">
      <iframe
        title="video"
        width='100%'
        height='auto'
        marginHeight="0"
        marginWidth="0"
        className="iframe-home"
        src='https://my.matterport.com/show/?m=nyaSYRL5A8t&play=1'
        frameBorder="0"
        allowFullScreen
        allow='xr-spatial-tracking'>
      </iframe>

      <Button />
    </div>
  )
}

export default Vital