import React from 'react'

const Footer = () => {
  return (
    <footer className="ml-5 mr-5 md:ml-10 md:mr-10 lg:ml-20 lg:mr-20 xl:ml-40 xl:mr-40">
      <div className="grid grid-cols-1 py-8 px-6 md:grid-cols-3 text-center md:text-left">
        <div>
          <h2 className="mb-6 text-sm font-semibold text-[#045A4B] uppercase">
            1448/17, L3 Building,
            Unit 101-102, Latphrao 87 (Chanthrasuk) Klongchan, Bang Kapi, Bangkok 10240
          </h2>
        </div>
        <div className='mx-auto'>
          <h2 className="mb-6 text-sm font-semibold text-[#045A4B] uppercase ">
            <a href="https://b-healthy.me/th/pages/contact-us" className='ml-0'>
              Contact Us
            </a>
          </h2>
          <h2 className="mb-6 text-sm font-semibold text-[#045A4B] uppercase ">
            <a href="https://b-healthy.me/th/pages/platform-app" className='ml-0'>
              Platform & App
            </a>
          </h2>
          <h2 className="mb-6 text-sm font-semibold text-[#045A4B] uppercase ">
            <a href="https://b-healthy.me/th/pages/become-our-partner" className='ml-0'>
              Become Our Partner
            </a>
          </h2>
        </div>
        <div className='mx-auto sm:mt-5 md:mt-0'>
          <h2 className="text-sm font-semibold text-[#045A4B] uppercase mb-2">Download</h2>
          <a href="https://apps.apple.com/us/app/b-healthy/id1576297758" className="ml-0 mb-2">
            <img src="images/app_store.png" className="m-0 mb-3" alt="Logo" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.bhealthy.patient.production" className="ml-0 mb-2">
            <img src="images/google_play.png" className="m-0 mb-3" alt="Logo" />
          </a>
          <a href="https://appgallery.huawei.com/app/C106135597" className="ml-0 mb-2">
            <img src="images/Huawei_appgallery.png" className="mb-3" alt="Logo" />
          </a>
        </div>
      </div>
      <div className="py-6 px-4 flex items-center justify-center">
        <div className="flex mt-4 space-x-6 justify-center md:mt-0">
          <a href='https://www.facebook.com/bHealthyMe'>
            <svg className="w-10 h-10 text-[#045A4B] ml-0 mr-0" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
            <span className="sr-only">Facebook page</span>
          </a>
          <a href='https://www.instagram.com/bhealthyme/?utm_medium=copy_link'>
            <svg className="w-10 h-10 text-[#045A4B] ml-0" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" /></svg>
            <span className="sr-only">Instagram page</span>
          </a>
          <a href='https://page.line.me/?accountId=bhealthyme'>
            <svg  className="w-10 h-10 text-[#045A4B] ml-0" fill="currentColor" viewBox="0 0 18 16" aria-hidden="true"> <path fillRule="evenodd" d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z" clipRule="evenodd" /></svg>
            <span className="sr-only">Line</span>
          </a>
        </div>
      </div>
      <div className='pb-5'>
        <h5 className='text-[#045A4B] text-center'>© 2022 B-Healthy Asia Co., Ltd. All rights reserved</h5>
      </div>
    </footer>

  )
}

export default Footer