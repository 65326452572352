import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/home";
import Map from "./pages/map";
import Pavillion from "./pages/pavillion";
import PoolVilla from "./pages/poolVilla";
import RakXaGaya from "./pages/rakXaGaya";
import RakXaJai from "./pages/rakXaJai"
import Vital from "./pages/vital";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="map" element={<Map />} />
        <Route path="pavillion" element={<Pavillion />} />
        <Route path="poolVilla" element={<PoolVilla />} />
        <Route path="rakXaGaya" element={<RakXaGaya />} />
        <Route path="rakXaJai" element={<RakXaJai />} />
        <Route path="Vital" element={<Vital />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;