import React from 'react'

const Nav = () => {
  return (
    <nav className="pt-4 ml-0">
      <div className="container flex justify-start mx-auto md:ml-0">
        <a href="/" className="ml-0">
          <img src="images/Horizontal.png" className="ml-0 mr-3 h-14 sm:h-6 md:h-16 lg:h-20 xl:h-20" alt="Logo" />
        </a>
      </div>
    </nav>
  )
}

export default Nav
