import React from 'react'
import Footer from '../components/footer'
import Nav from '../components/nav'
import { Link } from 'react-router-dom'

const Home = () => {

  return (
    <>
      <div className='ml-5 mr-5 md:ml-10 md:mr-10 lg:ml-20 lg:mr-20 xl:ml-40 xl:mr-40'>

        <div className='mg-all'>
          <Nav />
        </div>

        <div className='mb-10 mg-all'>
          <div className=''>
            <div className=''>
              <div className="relative mt-5 m-0 h-0 overflow-hidden aspect-w-16 aspect-h-9" style={{ paddingBottom: '56.25%' }}>
                <iframe
                  title='video'
                  src="videos/Corporate.mp4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 rounded-xl m-0"
                // className="absolute top-0 left-0 size-video rounded-xl m-0"
                />
              </div>
            </div>

            <div className='mt-10'>
              <h2 className='text-center font-bold'>About Us</h2>
              <p className='mt-3'>B-Healthy is an innovative digital platform that provides marketplace where all wellness packages are offered by health coaches, wellness clinics, wellness hotels and resorts. Each package will connect customer to B-Healthy digital health mobile application which customer will use to consult with their coaches along their
                package. </p>
              <p className='mt-3'>Customer will have access to modern cloud-native mobile application which they will use to keep track of their health data, communicate with health coaches and providers of their purchased packages via questionnaires, texting, and video call as well as manage appointments right from the app.</p>
              <p className='mt-8'>บี-เฮลธี คือผู้ให้บริการ Marketplace สำหรับแพ็กเกจส่งเสริมสุขภาพโดยผู้เชี่ยวชาญชั้นนำ ไม่ว่าจะเป็น โค้ชด้านสุขภาพ คลินิกสุขภาพ โรงแรมเพื่อสุขภาพ แต่ละแพ็คเกจจะเชื่อมต่อลูกค้ากับแอปพลิเคชั่นบนมือถือซึ่งลูกค้าจะใช้เป็นเครื่องมือในการรับปรึกษากับโค้ชของพวกเขา </p>
              <p className='mt-3'>ลูกค้าจะสามารถเข้าถึงแอปพลิเคชั่นมือถือบนคลาวด์ที่ทันสมัย ซึ่งพวกเขาจะใช้ในการบันทึกข้อมูลด้านสุขภาพสื่อสารกับโค้ชและผู้ให้บริการ
                แพ็คเกจที่ซื้อผ่านการทำแบบสอบถาม การส่งข้อความ และวิดีโอ และการทำนัดหมาย</p>
            </div>

            <div className='text-center mt-5 md:mt-10'>
              <button class="bg-gradient-to-b from-[#0DCDB6] to-[#045A4B] text-white py-2 px-4 rounded">
                <Link to="pavillion">
                  360 Tour
                </Link>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div className='bg-gradient-to-b from-[#FFFFFF] to-[#3EA694] ml-0 mr-0 mg-footer'>
        <Footer />
      </div>
    </>
  )
}

export default Home