import React from 'react'
import { Link } from 'react-router-dom'

function Map() {

  const myStyle = {
    backgroundImage:
      "url('/images/Map View.png')",
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(3px)',
    webkitFilter: 'blur(3px)',
  };

  return (
    <div >

      <div className='body-map m-0 p-0' style={myStyle}></div>
      <div className='absolute map-absolute'>
        <div className='relative'>
          <img
            className='img-map rounded-xl'
            src="images/Map_002.png"
            alt=""
          />
        </div>
      </div>

      <div className='absolute absolute-mark-Pavillion'>
        <div className='relative'>
          <button className='btn-map py-2 px-4 rounded w-24 md:w-28 lg:w-40'>
            <Link to="/pavillion">
              <p className="text-white text-sx text-sm md:text-base lg:text-lg">
                Pavillion
              </p>
            </Link>
          </button>
          <Link to="/pavillion">
            <img src="images/mark.png" alt="" className="mark-img ml-14 mt-1" />
          </Link>
        </div>
      </div>

      <div className='absolute absolute-mark-PoolVilla'>
        <div className='relative'>
          <button className='btn-map py-2 px-4 rounded w-24 md:w-28 lg:w-40'>
            <Link to="/poolVilla">
              <p className="text-white text-sx text-sm md:text-base lg:text-lg">
                Pool Villa
              </p>
            </Link>
          </button>
          <Link to="/poolVilla">
            <img src="images/mark.png" alt="" className="mark-img ml-14 mt-1" />
          </Link>
        </div>
      </div>

      <div className='absolute absolute-mark-RAKxaJAI'>
        <div className='relative'>
          <button className='btn-map py-2 px-4 rounded w-24 md:w-28 lg:w-40'>
            <Link to="/rakXaJai">
              <p className="text-white text-sx text-sm md:text-base lg:text-lg">
                RAKxa JAI
              </p>
            </Link>
          </button>
          <Link to="/rakXaJai">
            <img src="images/mark.png" alt="" className="mark-img ml-14 mt-1" />
          </Link>
        </div>
      </div>

      <div className='absolute absolute-mark-VitalLife'>
        <div className='relative'>
          <button className='btn-map py-2 px-4 rounded w-24 md:w-28 lg:w-40'>
            <Link to="/vital">
              <p className="text-white text-sx text-sm md:text-base lg:text-lg">
                Vital Life
              </p>
            </Link>
          </button>
          <Link to="/vital">
            <img src="images/mark.png" alt="" className="mark-img ml-14 mt-1" />
          </Link>
        </div>
      </div>

      <div className='absolute absolute-mark-RAKxaGAYA'>
        <div className='relative'>
          <button className='btn-map py-2 px-4 rounded w-24 md:w-28 lg:w-40'>
            <Link to="/rakXaGaya">
              <p className="text-white text-sx text-sm md:text-base lg:text-lg">
                RAKxa GAYA
              </p>
            </Link>
          </button>
          <Link to="/rakXaGaya">
            <img src="images/mark.png" alt="" className="mark-img ml-14 mt-1" />
          </Link>
        </div>
      </div>

    </div>
  )
}

export default Map