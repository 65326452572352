import React from 'react'

const Button = () => {

  const [showModal, setShowModal] = React.useState(false);
  const [showGroup, setShowGroup] = React.useState(false);

  const Show = () => {
    if(showGroup) {
      setShowGroup(false)
    } else {
      setShowGroup(true)
    }
  }

  return (
    <>
      {/* mobile */}
      <div className='absolute-group top-Group-absolute visible md:invisible'>
        <button
          className='bg-black p-3 inline-block rounded-full bg-opacity-30'
          onClick={() => Show()}
        >
          <p className='text-white fill-current flex items-center'>
            {showGroup ? (
              <iconify-icon icon="ep:close-bold" width="20"></iconify-icon>
            ) :
              <iconify-icon icon="pepicons:menu" width="20"></iconify-icon>
            }
          </p>
        </button>
      </div>

      {showGroup ? (
        <>
          <div className="absolute-btn top-btn-absolute">
            <div className='grid grid-cols-1 py-8 md:py-0 px-6 lg:grid-cols-3 gap-3'>
              <div className=''>
                <button
                  className='btn-bt py-2 px-4 rounded w-40 md:w-40'
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'map';
                  }}
                >
                  <p className="text-white md:text-lg">
                    Map View
                  </p>
                </button>
              </div>
              <div className=''>
                <button
                  className='btn-bt py-2 px-4 rounded w-40 md:w-40'
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://b-healthy.me/search?type=product&q=RAKxa';
                  }}
                >
                  <p className="text-white md:text-lg">
                    Select Package
                  </p>
                </button>
              </div>
              <div className=''>
                <button
                  className='btn-bt py-2 px-4 rounded w-40 md:w-40'
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  <p className="text-white md:text-lg">
                    Consult Expert
                  </p>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* PC - VR */}
      <div className="absolute-btn top-btn-absolute invisible md:visible">
        <div className='grid grid-cols-1 py-8 md:py-0 px-6 lg:grid-cols-3 gap-3'>
          <div className=''>
            <button
              className='btn-bt py-2 px-4 rounded w-40 md:w-40'
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'map';
              }}
            >
              <p className="text-white md:text-lg">
                Map View
              </p>
            </button>
          </div>
          <div className=''>
            <button
              className='btn-bt py-2 px-4 rounded w-40 md:w-40'
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://b-healthy.me/search?type=product&q=RAKxa';
              }}
            >
              <p className="text-white md:text-lg">
                Select Package
              </p>
            </button>
          </div>
          <div className=''>
            <button
              className='btn-bt py-2 px-4 rounded w-40 md:w-40'
              type="button"
              onClick={() => setShowModal(true)}
            >
              <p className="text-white md:text-lg">
                Consult Expert
              </p>
            </button>
          </div>
        </div>
      </div>

      <div className="h-auto">
        <div className="absolute top-btn-absolute-text left-1/2 transform -translate-x-1/2 -translate-y-0/4">
          {showModal ? (
            <>
              {/*content*/}
              <div className="border-0 rounded-lg relative flex flex-col w-50 h-50 md:w-80 model-contact outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="md:text-3xl text-white">
                    Contact Us
                  </h3>
                  <button
                    className="p-1 ml-auto border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <p className="text-white h-6 w-6 text-2xl outline-none focus:outline-none">
                      X
                    </p>
                  </button>
                </div>
                {/*body*/}
                <div className="py-6 px-4 flex items-center justify-center">
                  <div className="flex mt-0 space-x-9 justify-between ">
                    <a href='http://line.me/ti/p/@bhealthyme' target="_blank" rel="noreferrer">
                      <img src="images/line.png" alt="" className="img-icon" />
                      <span className="sr-only">Line</span>
                    </a>
                    <a href='https://www.messenger.com/t/bHealthyMe' target="_blank" rel="noreferrer">
                      <img src="images/ms.png" alt="" className="img-icon" />
                      <span className="sr-only">Messenger</span>
                    </a>
                    <a href='https://wa.me/66622256692' target="_blank" rel="noreferrer">
                      <img src="images/ws.png" alt="" className="img-icon" />
                      <span className="sr-only">Whatsapp</span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Button